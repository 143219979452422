<template>
 <Default>
    <div class="usuarios">
    <banner
      :fondo="usersBg"
      titulo="Usuarios"
      descripcion="Lista de usuarios"
    ></banner>

    <section class="main__content">
      <div class="card">
        <div class="card-header row no-gutters align-items-center align-center">
          <section class="d-flex align-items-center col-12 col-md-6 col-lg-2">
            <span class="small text-muted mr-2">Mostrar</span>

            <select
              class="form-control form-control-sm nro-items"
              @change="getItems()"
              v-model="number_paginate"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </section>

          <section
            class="d-flex align-items-center col-12 col-md-6 mt-2 mt-md-0 pl-md-3 col-lg-3"
          >
            <span class="small text-muted mr-2">Tipo</span>

            <select
              class="form-control form-control-sm"
              v-model="tipoUsuario"
              @change="getItems()"
            >
              <option value="1">Administrador</option>
              <option value="2">Supervisor</option>
              <option value="3">Contratista</option>
              <option value="4">Finanzas</option>
            </select>
          </section>

          <div class="col-12 col-md-6 mt-2 mt-md-2 col-lg-4">
            <input
              type="search"
              placeholder="Buscar por ID o nombre"
              class="form-control search"
              v-model="query"
            />
          </div>

          <router-link
            :to="{ name: 'usuarios-crear' }"
            class="btn btn-dark btn-md col-12 col-md-6 mt-2 mt-md-2 btn-block col-lg-3"
          >
            <i class="fas fa-plus mr-2"></i>
            Registrar Nuevo Usuario
          </router-link>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-custom" v-if="items.length">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Foto</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Nro. documento</th>
                  <th scope="col">Celular</th>
                  <th scope="col">Email</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemsFiltrados" :key="item.userId">
                  <td data-label="ID" scope="row">{{ item.userId }}</td>
                  <td data-label="Foto" scope="row">
                    <img
                      :src="item.foto"
                      :alt="item.nombres"
                      class="table__image"
                    />
                  </td>
                  <td data-label="Nombre">
                    <p class="small mt-2 mb-0">
                      {{ item.nombres }} {{ item.apellidos }}
                    </p>
                  </td>
                  <td data-label="Nro. documento">
                    <p class="small mt-2 mb-0">{{ item.nroDocumento }}</p>
                  </td>
                  <td data-label="Celular">
                    <p class="small mt-2 mb-0">{{ item.celular }}</p>
                  </td>
                  <td data-label="Email">
                    <p class="small mt-2 mb-0">{{ item.email }}</p>
                  </td>
                  <td data-label="Acciones">
                    <router-link
                      :to="{
                        name: 'usuarios-editar',
                        params: { id: item.userId, userData: item },
                      }"
                    >
                      <button
                        type="button"
                        class="btn btn-success btn-sm mr-2"
                        v-b-tooltip.hover
                        title="Editar"
                      >
                        <i class="fas fa-pen-alt"></i>
                      </button>
                    </router-link>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      v-b-tooltip.hover
                      title="Eliminar"
                      @click="cambiarEstadoModalEliminar(true, item)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <p class="text-muted text-center" v-else>
              No existen usuarios registrados
            </p>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="modal-eliminar-producto" hide-header hide-footer centered>
      <div class="text-center" v-if="itemSeleccionado">
        <p class="my-4">
          ¿Está seguro de eliminar al usuario
          <span class="font-weight-bold"
            >{{ itemSeleccionado.nombres }}
            {{ itemSeleccionado.apellidos }}</span
          >?
        </p>

        <div class="text-right">
          <button type="button" class="btn btn-link" @click="cancelar()">
            Volver
          </button>

          <button
            type="button"
            class="btn btn-danger"
            :disabled="loadingAccion"
            @click="eliminar()"
          >
            {{ loadingAccion ? "Eliminando..." : "Eliminar" }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
 </Default>
</template>

<script>
import GetAllUsers from "@/apollo/queries/usuarios/GetAllUsers.gql";
import DeleteUsuario from "@/apollo/mutations/usuarios/DeleteUsuario.gql";
import Default from "@/layouts/default.vue";

import Banner from "@/components/global/Banner";
import usersBg from "@/assets/banner/users.jpg";

export default {
  data() {
    return {
      items: [],
      query: null,
      number_paginate: 10,
      tipoUsuario: 1,
      itemSeleccionado: null,
      loadingAccion: false,
      usersBg: usersBg,
    };
  },
  mounted() {
    this.getItems();
  },
  components: {
    Banner, Default
  },
  methods: {
    getItems() {
      this.query = null;

      this.$apollo
        .query({
          query: GetAllUsers,
          variables: {
            tipoUsuario: this.tipoUsuario,
            estado: "",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.items = response.data.GetAllUsers.reverse();
        });
    },
    cambiarEstadoModalEliminar(mostrar, item) {
      if (mostrar) {
        this.itemSeleccionado = item;

        this.$bvModal.show("modal-eliminar-producto");
      } else {
        this.$bvModal.hide("modal-eliminar-producto");

        this.itemSeleccionado = null;
      }
    },
    eliminar() {
      this.loadingAccion = true;

      let userId = parseInt(this.itemSeleccionado.userId);

      this.$apollo
        .mutate({
          mutation: DeleteUsuario,
          variables: {
            input: {
              userId,
            },
          },
        })
        .then(() => {
          this.eliminarPorIndice();

          this.loadingAccion = false;

          this.cambiarEstadoModalEliminar(false);

          this.$bvToast.toast("Usuario eliminado", {
            // title: `Variant ${variant || "default"}`,
            variant: "success",
            solid: true,
          });
        });
    },
    eliminarPorIndice() {
      let index = this.items.indexOf(this.itemSeleccionado);

      this.items.splice(index, 1);

      this.itemSeleccionado = null;
    },
    cancelar() {
      this.loadingAccion = false;

      this.cambiarEstadoModalEliminar(false);
    },
  },
  computed: {
    itemsFiltrados: function() {
      let data = this.items;

      if (this.query) {
        let queryMinuscula = this.query.toLowerCase();

        data = this.items.filter(
          (item) =>
            item.userId.includes(this.query) ||
            item.nombres.toLowerCase().includes(queryMinuscula) ||
            item.apellidos.toLowerCase().includes(queryMinuscula)
        );
      }

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.nro-items {
  width: 100%;
}
.search {
  width: 100%;
}
</style>

<style lang="scss">
.table {
  &__image {
    width: 40px;
    height: 40px;

    border-radius: 50%;
  }
}

@media (min-width: 768px) {
  .no-gutters > [class="col-"] {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
}
</style>
